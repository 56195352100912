* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%) no-repeat center center fixed;
  background-size: cover;
  /* Source: 010 Winter Neva from https://webgradients.com/ */
}